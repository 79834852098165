/**
 * Role auth levels:
 *
 * su = 100
 * admin = 60
 * storeAdmin = 40
 * manager = 25
 * associate = 10
 *
 */
// NB: temporarily redirecting all but TNT and dev users to get-it
const _rules = {
  "get-it-now": (usrlvl) => usrlvl > 0,
  "customize-it": (usrlvl) => usrlvl >= 8,
  "book-it": (usrlvl) => usrlvl >= 8,

  "/": (usrlvl) => (usrlvl < 8 ? { path: "/shop/get-it-now" } : true),
  "/myaccount/ecertificates": (usrlvl) =>
    usrlvl >= 25 ? true : { name: "myaccount" },

  "admin.access": (usrlvl) => usrlvl > 30,
  // role selectors
  "is.su": (usrlvl) => usrlvl === 100,
  "is.admin": (usrlvl) => usrlvl === 60,
  "is.storeAdmin": (usrlvl) => usrlvl === 40,
  "is.manager": (usrlvl) => usrlvl === 25,
  "is.associate": (usrlvl) => usrlvl === 15,
  "is.dealer": (usrlvl) => usrlvl === 10,

  "cc.only": (usrlvl) => usrlvl < 8,
  "payment.methods": (usrlvl) =>
    usrlvl < 8 ? ["cc"] : usrlvl < 25 ? ["cc", "po"] : ["cc", "po", "credit"],
};

export default _rules;

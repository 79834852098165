import _ from "lodash/fp";
const _storename = "nedco";
let _host = "";
let _origin = window.location.origin;

if (
  ~window.location.hostname.indexOf("localhost") ||
  ~window.location.hostname.indexOf("10.0.2.2")
) {
  _host = `http://${window.location.hostname}:9094`;
  _origin = `http://${window.location.hostname}:9094`;
} else if (~window.location.hostname.indexOf("sparkable")) {
  _host = "/" + _storename.toLowerCase();
}

const constants = {
  // if using auth0
  AUTH_CLIENT_ID: "",
  AUTH_CLIENT_DOMAIN: "",

  STORE: _storename,
  HOST: _host,
  ORIGIN: _origin,
  API_URL: _host + "/api/",
  PUBLIC_URL: _host + "/api/public/",
  LOGIN_URL: _host + "/api/public/login",
  SIGNUP_URL: _host + "/api/public/signup",

  DEFAULT_LANG: "en",
  LOCALES: ["en", "fr"],
  // some control config that should be common to server configs,
  // here so we don't have to go to the database to know how to show stuff
  storeconfig: {
    requireLogin: true,
    allowAnonymous: false,
    selfRegister: true,
    requireApproval: true,
    onlyAdminCreateUsers: false,
    onlyImportUsers: false,
  },

  MODEL: {
    types: {
      string: (val) => typeof val === "string",
      email: (val) => typeof val === "string",
      number: (val) => _.isFinite(val),
      array: (val) => _.isArray(val),
      object: (val) => _.isObject(val),
    },
    match: {
      email:
        /^[A-Z0-9_!#$%&'*+/=?`{|}~^-]+(?:\.[A-Z0-9_!#$%&'*+/=?`{|}~^-]+)*@[A-Z0-9-]+(?:\.[A-Z0-9-]+)*$/i,
    },
  },
};

export default constants;

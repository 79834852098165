const en = {
  test_alert: "this is a test of the alert system...",
  system: {
    error: {
      moneris: {
        token: {
          940: "There is a problem with our connection to the gateway. Try again shortly or contact a service rep.",
          941: "The gateway had a problem completing the request. Try again shortly or contact a service rep.",
          942: "There is a problem with our connection to the gateway. Try again shortly or contact a service rep.",
          943: "There seems to be a problem with the card number you entered. Please check that it is correct and try again.",
          944: 'The expiration date you entered is not valid. The format must be "MMYY" and must be the date on your card (and must be in the future).',
          945: "The CVD number you entered is not correct for this card number.",
        },
      },
    },
  },
  hello: "hello",
  profile: "Profile",

  getIt: "GET IT NOW",
  bookIt: "BOOK IT",
  customizeIt: "CUSTOMIZE IT",
  taxonomy: {
    pagetype: {
      post: "Post",
      page: "Page",
    },
  },
  moneris: {
    card: {
      V: "VISA",
      M: "MASTERCARD",
    },
  },

  upsServiceTypeMap: {
    70: "UPS Access Point Economy", // Canadian domestic shipments
    12: "UPS 3 Day Select", // Shipments originating in Canada
    "08": "UPS Worldwide Expedited International", // Shipments originating in Canada
    "01": "UPS Express", // Canadian domestic shipments
    "02": "UPS Expedited", // Canadian domestic shipments
    13: "UPS Express Saver", // Canadian domestic shipments
    14: "UPS Express Early", // Canadian domestic shipments
    65: "UPS Express Saver", // Shipment originating in Canada (Domestic & Int'l)
    11: "UPS Standard", // Shipments originating in Canada (Domestic and Int’l)
    "07": "UPS Worldwide Express", // International shipments originating in Canada
    54: "UPS Worldwide Express Plus", // International shipments originating in Canada
    TBD: "Order exceeds max weight for single package, get an estimate and update the order.",
  },
  common: {
    brand: "{{appconfig.siteConfig.brand}}",
    siteName: "{{appconfig.siteConfig.siteName}}",
    name: "Name",
    description: "Description",
    type: "Type",
    discard: "Discard",
    cancel: "Cancel",
    delete: "Delete",
    feature: "feature",
    features: "features",
    edit: "Edit",
    note: "Note:",
  },
  sizes: {
    ONE: "ONE",
    JM: "JM",
    JL: "JL",
    JX: "JXL",
    P: "P",
    XS: "XS",
    S: "S",
    M: "M",
    L: "L",
    XL: "XL",
    "2X": "2X",
    "3X": "3X",
    "4X": "4X",
    "5X": "5X",
    "S/M": "S/M",
    "L/XL": "L/XL",
  },
  buttons: {
    home: "Home",
    addToCart: "Add to Cart",
    clearCart: "Clear Cart",
    continueShopping: "Continue Shopping",
    checkout: "Checkout",
    completeCheckout: "Complete Checkout",
    completeReservation: "Complete Reservation",
    remove: "Remove",
    edit: "Edit",
    change: "Change",
    purchase: "Purchase",
    transfer: "Transfer",
    redeem: "Redeem",
    save: "Save",
    create: "Create",
    update: "Update",
    reset: "Reset",
    continue: "Continue",
    backToCart: "Back to Cart",
    previousItem: "< Previous Item",
    nextItem: "Next Item >",
    backToReservation: "Back to Reservation",
    chooseAvailLoc: "Choose from available locations",
    useThisAdd: "Use this address",
    clickHere: "CLICK HERE",
    submit: "Submit",
    myAccount: "My Account",
    submitOrder: "Submit items to Order",
    close: "Close",
    deleteReservation: "Delete Entire Reservation",
    done: "Done",
  },
  tableHeaders: {
    date: "Date",
    orderDate: "Order Date",
    order: "Order #",
    subtotal: "Subtotal",
    total: "Total",
    itemsTotal: "Items Total",
    status: "Status",
    view: "View",
    sku: "SKU",
    name: "Name",
    item: "Item",
    size: "Size",
    qty: "Qty",
    price: "Price",
    tax: "Tax",
    taxes: "Taxes",
    shipping: "Shipping",
    item_id: "Item ID",
    inStock: "In Stock",
    purchaseDate: "Purchase Date",
    redemptionDate: "Redemption Date",
    code: "Code",
    amount: "Amount",
    balance: "Balance",
    expires: "Expires",
    type: "Type",
    id: "ID",
    recipient: "Recipient",
    nameSize: "Name/Size(s)",
    discount: "Discount",
  },
  forms: {
    required: "Required fields",
    firstName: "First name",
    lastName: "Last name",
    type: "Type",
    address: "Address",
    address2: "Apt, suite, etc. (optional)",
    city: "City",
    province: "Province",
    country: "Country",
    postalCode: "Postal code",
    phone: "Phone",
    email: "Email",
    custType: "Customer Type",
    dealerName: "Dealer Name",
    dealerNo: "Dealer Number",
    howMuch: "How much is?",
    answer: "Answer",
  },
  status: {
    inProgress: "In Progress",
    "in progress": "In Progress",
    new: "New",
    pending: "Pending",
    complete: "Complete",
    cancelled: "Cancelled",
    declined: "Declined",
    hold: "Hold",
  },
  modal: {
    program: {
      title: "Welcome to Book It",
      disclaimer: "I agree to the disclaimer above",
      continue: "Continue",
      cancel: "Cancel",
    },
    order: {
      title: "Note:",
      disclaimer: "I acknowledge that I am responsible to full payment",
      method: "Upon final billing I would like to:",
      cc: "Be contacted for credit card payment (Note: minimum order for credit card payment is $250)",
      po: "Pay by invoice",
      continue: "Continue",
      cancel: "Cancel",
    },
    custom: {
      title: "Welcome to Customize It",
      disclaimer: "I agree to the above disclaimer",
      continue: "Continue",
      cancel: "Cancel",
    },
  },
  admin: {
    common: {
      permissions: "Permissions",
      path: "Path",
      entity: "Entity",
      property: "Property",
      type: "Type",
    },
    locales: {
      en: "English",
      fr: "French",
    },
    login: {
      title: "Login",
    },
    title: {
      editRuleset: "Edit Ruleset",
      addRuleset: "Add Ruleset",
    },
    permission: {
      operation: {
        create: "Create",
        read: "Read",
        update: "Update",
        delete: "Delete",
      },
      type: {
        route: "Route",
        entity: "Entity",
        property: "Property",
      },
    },
  },
  sections: {
    bookIt: {
      dealerLogo: "Dealer Logo Option",
      minNotice:
        "For a minimum of {customMinQty} units, you can add your dealer logo for an additional ${customUnitPrice} per unit",
      // 'minNotice': 'For a minimum of 12 units, you can add your dealer logo for an additional ${customUnitPrice} per unit',
      minNotMet: "*Minimum quantity not met",
      addLogo: "Add your Dealer Logo",
      // 'emailLogo': 'Please email your logo file to',
      currentPrice: "Current Price",
      minQty: "Min Book It QTY",
      quantity: "Quantity",
      quantityab: "Qty",
      enterQuantity: "Enter Your Qty",
      totalQuantity: "Total Quantity",
      minQtyReq: "Group Buy Min Qty. Required",
      totalQty: "Total Current Group Buy Qty.",
      reserveNow: "Reserve Now",
      emailLogo: "Please email your logo file to",
      programThankyou:
        "Product ordered through the Book It Program are considered final sale. Billing will be at the final price break at closing based on the quantity ordered as a group. You will be invoiced at the time of shipping, which can be up to 6 months.",
    },
    customizeIt: {
      chooseLogoPlacement: "CHOOSE LOGO PLACEMENT",
      coloursAvail: "Colours Available",
      addLogosCost: "Additional Logos Cost",
      costCurrentDesign: "Your price for this item with your custom design",
      placeOrder: "Place Order as Designed",
      platformWarning:
        "Design platform is used as a guide only. You will be emailed a final proof for approval before production. Final logo size and position may vary.",
      enterOrderQty: "Enter order quantity",
      totalQuantity: "Total Qty",
      totalQuantityab: "Qty",
      changeLogo: "Change Current Logo",
      yourLogos: "Your Logos",
      clearLogo: "Clear Logo",
      uploadLogo: "Upload Your Logo",
      emailQuote: "Email for Quote",
      guideOnly:
        "This is a virtual representation only. You will be emailed a final proof for approval before production.",
      customLogos: "Custom Logos",
      specialInst: "Special Instructions",
      logosLowAs:
        "Our Price includes one logo. Additional logos are as low as {logosMinPrice}. Your pricing below will be updated as you create your design.",
      defaultLogo: "Default logo: FREE",
      customThankyou:
        "Customize It orders are made to order and can take up to 2 – 3 weeks for final delivery. You will be emailed a final proof for approval before production. Customize It orders are final sale. You will be billed at time of shipping. Lower priced items are subject to plus or minus 5% of the quantity ordered. You will be billed for final quantity shipped.",
    },
  },
  client: {
    minQtyReq: "Min. Qty.:",
    navigation: {
      home: "HOME",
      contactUs: "Contact Us",
      login: "LOG IN",
      logout: "LOG OUT",
      myCart: "My Cart",
      noItems: "NO ITEMS",
      item: "ITEM",
      units: "UNITS",
      returns: "Returns",
      sizeCharts: "Size Charts",
      userGuide: "User Guide",
      currency: "All Currency is in CAD",
      policies: "Policies",
      support: "Support",
    },
    prodNav: {
      shopMen: "Shop Men",
      shopWomen: "Shop Women",
      tShirts: "T-Shirts",
      polos: "Polos",
      fleece: "Fleece",
      jackets: "Jackets",
      headwear: "Headwear",
      classicHonda: "Classic Honda",
      new: "NEW",
      pe: "PE",
      marine: "Marine",
      engine: "Engine",
      counterShirts: "Counter Shirts",
      drinkware: "Drinkware",
      office: "Office",
      bags: "Bags",
      specials: "Specials",
      clearance: "Clearance",
      goldwing: "Goldwing",
      "show-shirts": "Show Shirts",
      technology: "Technology",
      gifts: "Gifts",
      safety: "Safety",
    },
    login: {
      title: "Welcome to {siteName}.com",
      message: "Please Login",
      email: "Email",
      username: "Username",
      password: "Password",
      forgotPassword: "Forgot Password?",
      account: "Don't have an account?",
      request: "Request an Account",
      resetPassword: "Reset Password",
      backToLogin: "Back to Login",
      firstTime:
        "*First time users will be approved by {brand} before being provided a password.",
      hoManagement: "H.O. Management",
      dealerManagement: "Dealer Management",
      hoAssociate: "H.O. Associate",
      dealerStaff: "Dealer Staff",
      managementMarketingTeam: "Management Marketing Team",
      branchManager: "Branch Manager",
      executiveAssistant: "Executive Assistant",
      categoryManager: "Category Manager",
      sales: "Sales",
      employee: "Employee",
      customer: "Customer",
      vendor: "Vendor",
      carrierDealer: "Carrier Dealer",
      bryantDealer: "Bryant Dealer",
      carrierFadDealer: "Carrier FAD Dealer",
      bryantFadDealer: "Bryant FAD Dealer",
      ceManagement: "CE Management",
      ceEmployee: "CE Employee",
      select: "Select",
      viewOtherLang: "Voir cette page en français",
      submitted: "Your request has been submitted",
      submittedNoApproval: "Success! Your account is setup",
      notification: "You will receive notification of approval by email",
      notificationNoApproval:
        "Look for an email in your inbox with a link to complete your account",
      receivePassword: "You should receive a password reset email shortly",
    },
    activate: {
      setPassword: "Please set a new password to activate your account.",
      passwordUpdated: "Password successfully updated!  Loading login page...",
      activateAcc: "Activate your new Honda store account",
      confirmPassword: "Confirm Password",
      wrong: "Something went wrong...",
      enterUsername: "Enter a username",
    },
    home: {
      welcome: "Welcome",
      doToday: "What would you like to do today?",
      days: "DAYS",
      hours: "HOURS",
      mins: "MINS",
      secs: "SECS",
      timeLeft: "Time left to order on current booking",
      nextBooking: "Next Booking Opens",
      nextBookingSoon: "Next Booking Opens Soon!",
    },
    product: {
      zoom: "Zoom",
      related: "Related Items",
      new: "NEW!",
      clearance: "CLEARANCE!",
    },
    cart: {
      title: "Your Cart",
      cartEmpty: "Your cart is empty!",
      willBeCalculated: "Shipping and taxes will be calculated at checkout",
    },
    checkout: {
      title: "Checkout",
      customerInfo: "Customer information",
      shipMethod: "Shipping method",
      shipInfo: "Shipping Information",
      payMethod: "Payment Method",
      payInfo: "Payment Information",
      orderSummary: "Order summary",
      shipAdd: "Shipping address",
      billAdd: "Billing address",
      payAdd: "Payment address",
      shippingOptions: "Shipping Options",
      upsShipOptions: "UPS Shipping Options",
      canpostShipOptions: "Canada Post Shipping Options",
      shipWeight: "Package shipping weight",
      creditCard: "Credit Card",
      po: "PO",
      poNo: "PO#",
      poRef: "PO/Reference Number",
      secureMsg:
        "All transactions are secure and encrypted. Credit card information is never stored.",
      detailMsg:
        "Some details of your order must be fixed before you can checkout.",
      poMsg:
        "All orders over $300 will quality for net 30 terms. Orders are billed when shipped.<br />Payment is required with 30 days of invoice to maintain terms.",
      lbs: "lbs total",
      authNo: "Auth. #",
      editShipAdd: "Edit Shipping Address",
      invalidShippingAddress: "Incomplete or invalid shipping address",
      editBillAdd: "Edit Billing Address",
      closeEdit: "Close Edit",
      totalCad: "Total CAD",
      totalBeforeCredit: "Total CAD (Before Credit)",
      creditApplied: "Credit Applied",
      confirmAmountCredit: "Confirm amount from Credit",
      inventoryWarningRow:
        "Since putting this item in your cart, there is now insufficient inventory to complete your order.",
      inventoryWarningCart:
        "One or more items in your cart have insufficient inventory to complete your order. Please update your quantities so they don't exceed available supply.",
      availableInventory: "Available inventory =",
      shippingTieredOver: "TBC",
      shippingStandardOver: "TBD",
      shipping: {
        weightOver:
          "Due to the weight of your order we will contact you with final shipping cost later. A flat rate of $200 will be added during checkout.",
        valueOver:
          "Due to the value of your order we will contact you with final shipping cost later.",
      },
      payment: {
        card: "Numerical characters only, no hyphens, spaces or slashes",
        expiry:
          "4 numerical characters only (MMYY), no hyphens, spaces or slashes",
        cvd: "3 or 4 numerical characters only",
      },
      receipt: {
        receipt: "Receipt",
        title: "Invoice",
        customTitle: "Custom Order Confirmation",
        receiptTitle: "Order Confirmation",
        resTitle: "Your Reservation is Confirmed",
        thankYou: "Thank you for your order!",
        transfer: "Thank you!",
        message:
          "Customize It items are made to order and can take up to 2 – 3 weeks for delivery. You will be emailed a final proof for approval before production. Your Honda CSR rep will provide an estimated delivery date upon proof approval.",
        ecertMessage: "You will receive a receipt by email.",
        issueInvoice: "We will issue you an invoice.",
        emailInvoice: "An invoice will be emailed to you.",
      },
      discountStr: "NEW DISCOUNT STRUCTURE!",
    },
    myAccount: {
      title: "My Account",
      myOrders: "My Orders",
      viewOrders: "View and print invoices of past orders",
      accountSettings: "My Account Settings",
      viewInfo: "View and manage your customer information",
      manage: "View and manage your eCertificates",
      shoppingResources: "Shopping Resources",
      myCart: "My Cart",
      viewCart: "View current cart items",
      viewCartEmpty: "You have no items in your cart",
      customerInfo: {
        title: "Customer Information",
        userInfo: "User Information",
        manageLocations: "Manage Locations",
        personalLoc: "Personal Locations",
        BRANDLoc: "HondaGear Locations",
        checkoutDetails: "Checkout Defaults",
        defaultShip: "Default Ship To Location",
        setDefaultShip: "Set a default Shipping Address",
        defaultBill: "Default Bill To Location",
        setDefaultBill: "Set a default Billing Address",
        addLocation: "Add Location",
        backOrders: "Back to Orders",
        backMyAccount: "Back to My Account",
        editAdd: "Edit Address",
        deleteAdd: "Delete Address",
      },
      orderHistory: {
        title: "Order History",
        orderDetails: "Order Details",
        orderInvoice: "Order Invoice",
        viewInvoice: "View Invoice",
        reservationDetails: "Reservation Details",
        orderInfo: "Order Info",
        reservationInfo: "Reservation Info",
        orderItems: "Order Items",
        reservationItems: "Reservation Items",
        getItOrders: "Get It Now Orders",
        bookItReservations: "Book It Reservations",
        standard: "Get It Now",
        custom: "Customize It",
        reservation: "Book It",
        credit: "eCertificate",
      },
      ecertificate: {
        title: "eCertificates",
        ecertificate: "eCertificate",
        transferReceipt: "eCertificates Transfer Receipt",
        myEcertificates: "My eCertificates",
        currentBalance: "Current balance",
        history: "History",
        purchase: "Purchase",
        label: "Title",
        optional: "Optional",
        amount: "Amount",
        expire: "Expiry notice: ecertificates purchased today will expire on",
        recipient: "Recipient",
        recipientEmail: "Recipient's email",
        recipientMessage: "Recipient must be a registered user.",
        yourMessage: "Your message",
        enterYourMessage: "Enter your message",
        anotherUser: "Another User",
        myself: "Myself",
        buy: "Buy",
      },
      changePassword: {
        title: "Change Password",
        currentPass: "Current Password",
        newPass: "New Password",
        confirmPass: "Confirm New Password",
      },
      vault: {
        managePayMeth: "Manage Payment Methods",
        addCard: "Add a Card",
        submitCard: "Submit Card",
        vaultNoCard:
          "There is no credit card attached to your account. Please add a card.",
      },
    },
  },
};

export default en;

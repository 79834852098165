const fr = {
  test_alert: "this is a test of the fr alert system...",
  system: {
    error: {
      moneris: {
        token: {
          940: "There is a problem with our connection to the gateway. Try again shortly or contact a service rep.",
          941: "The gateway had a problem completing the request. Try again shortly or contact a service rep.",
          942: "There is a problem with our connection to the gateway. Try again shortly or contact a service rep.",
          943: "There seems to be a problem with the card number you entered. Please check that it is correct and try again.",
          944: 'The expiration date you entered is not valid. The format must be "MMYY" and must be the date on your card (and must be in the future).',
          945: "The CVD number you entered is not correct for this card number.",
        },
      },
    },
  },
  hello: "hello",
  profile: "Profile",

  getIt: "Procurez-vous-le maintenant",
  bookIt: "Réservez-le",
  customizeIt: "Procurez-vous-le maintenant",

  taxonomy: {
    pagetype: {
      post: "Post",
      page: "Page",
    },
  },
  moneris: {
    card: {
      V: "VISA",
      M: "MASTERCARD",
    },
  },
  upsServiceTypeMap: {
    70: "UPS Access Point Economy", // Canadian domestic shipments
    12: "UPS 3 Day Select", // Shipments originating in Canada
    "08": "UPS Worldwide Expedited International", // Shipments originating in Canada
    "01": "UPS Express", // Canadian domestic shipments
    "02": "UPS Expedited", // Canadian domestic shipments
    13: "UPS Express Saver", // Canadian domestic shipments
    14: "UPS Express Early", // Canadian domestic shipments
    65: "UPS Express Saver", // Shipment originating in Canada (Domestic & Int'l)
    11: "UPS Standard", // Shipments originating in Canada (Domestic and Int’l)
    "07": "UPS Worldwide Express", // International shipments originating in Canada
    54: "UPS Worldwide Express Plus", // International shipments originating in Canada
    TBD: "Order exceeds max weight for single package, get an estimate and update the order.",
  },
  common: {
    brand: "HondaGear",
    siteName: "Site name FR",
    name: "Name",
    description: "Description",
    type: "Type",
    discard: "Discard",
    cancel: "Cancel",
    delete: "Delete",
    feature: "feature",
    features: "features",
    edit: "Edit",
    note: "Remarque :",
  },
  sizes: {
    ONE: "UN",
    JM: "JM",
    JL: "JL",
    JX: "JXL",
    P: "P",
    XS: "TP",
    S: "P",
    M: "M",
    L: "G",
    XL: "TG",
    "2X": "2TG",
    "3X": "3TG",
    "4X": "4TG",
    "5X": "5TG",
    "S/M": "P/M",
    "L/XL": "G/TG",
  },
  buttons: {
    home: "Page d’accueil",
    addToCart: "Ajouter au panier",
    clearCart: "Vider le panier",
    continueShopping: "Poursuivre le magasinage",
    checkout: "Passer la commande",
    completeCheckout: "Paiement",
    completeReservation: "Réservation complète",
    remove: "Retirer",
    edit: "Edit",
    change: "Changer",
    purchase: "Acheter",
    transfer: "Transfert",
    redeem: "Échanger",
    save: "Sauver",
    create: "Create",
    update: "Mise",
    reset: "Réinitialiser",
    continue: "Continuer",
    backToCart: "Retour au panier",
    previousItem: "< Article précédent",
    nextItem: "Prochain point >",
    backToReservation: "Retour à la réservation",
    chooseAvailLoc: "Choisissez parmi les emplacements disponibles",
    useThisAdd: "Utilise cette adresse",
    clickHere: "Cliquez ici",
    submit: "Soumettre",
    myAccount: "Mon compte",
    submitOrder: "Soumettre la commande",
    close: "Fermer",
    deleteReservation: "Supprimer toute la réservation",
    done: "Terminé",
  },
  tableHeaders: {
    date: "Date",
    orderDate: "Date de commande",
    order: "Numéro de commande",
    subtotal: "Sous-total",
    total: "Total",
    itemsTotal: "Total des articles",
    status: "Statut",
    view: "Vue",
    sku: "SKU",
    name: "Nom",
    item: "Article",
    size: "Grandeur",
    qty: "Qty",
    price: "Prix",
    tax: "Tax",
    taxes: "Taxes",
    shipping: "Livraison",
    item_id: "ID de l'article",
    inStock: "En stocks",
    purchaseDate: "Date d'achat",
    redemptionDate: "Date de rachat",
    code: "Code",
    amount: "Montant",
    balance: "Équilibre",
    expires: "Expire",
    type: "Type",
    id: "ID",
    recipient: "Bénéficiaire",
    nameSize: "Nom / Taille",
    discount: "Rabais",
  },
  forms: {
    required: "Champs requis",
    firstName: "Prénom",
    lastName: "Nom",
    type: "Type",
    address: "Adresse",
    address2: "Apt, suite, etc. (optionnel}",
    city: "Ville",
    province: "Province",
    country: "Pays",
    postalCode: "Code postal",
    phone: "Numéro de téléphone",
    email: "Courriel",
    custType: "Type de client",
    dealerName: "Le nom du revendeur",
    dealerNo: "Numéro du concessionnaire",
    howMuch: "Combien Calculez",
    answer: "Répondre",
  },
  status: {
    inProgress: "In Progress",
    "in progress": "In Progress",
    new: "New",
    pending: "Pending",
    complete: "Complete",
    cancelled: "Cancelled",
    declined: "Declined",
    hold: "Hold",
  },
  modal: {
    program: {
      title: "Bienvenue dans Pré-commander!",
      disclaimer: "J’accepte les conditions décrites ci-dessus",
      continue: "Continuer",
      cancel: "Cancel",
    },
    order: {
      title: "Remarque :",
      disclaimer: "I acknowledge that I am responsible to full payment",
      method: "Au moment de la facturation finale, j’aimerais :",
      cc: "Qu’on communique avec moi pour effectuer le paiement par carte de crédit. (Remarque : pour un montant d’au moins 250 $.)",
      po: "Payer selon les modalités de la facture;",
      continue: "Continuer",
      cancel: "Cancel",
    },
    custom: {
      title: "Bienvenue à Customize It",
      disclaimer: "J’accepte les conditions décrites ci-dessus",
      continue: "Continuer",
      cancel: "Cancel",
    },
  },
  admin: {
    common: {
      permissions: "Permissions",
      path: "Path",
      entity: "Entity",
      property: "Property",
      type: "Type",
    },
    login: {
      title: "S'identifier",
    },
    locales: {
      en: "English",
      fr: "French",
    },
    title: {
      editRuleset: "Edit Ruleset",
      addRuleset: "Add Ruleset",
    },
    permission: {
      operation: {
        create: "Create",
        read: "Read",
        update: "Update",
        delete: "Delete",
      },
      type: {
        route: "Route",
        entity: "Entity",
        property: "Property",
      },
    },
  },
  sections: {
    bookIt: {
      dealerLogo: "Option du logo du concessionnaire",
      minNotice:
        "Avec une commande d’au moins {customMinQty} unités, vous pouvez ajouter votre logo de concessionnaire pour {customUnitPrice} $ de plus par unité.",
      // 'minNotice': 'Avec une commande d’au moins 12 unités, vous pouvez ajouter votre logo de concessionnaire pour {customUnitPrice} $ de plus par unité.',
      minNotMet: "*Quantité minimale non atteinte",
      addLogo: "Ajouter votre logo de concessionnaire",
      // 'emailLogo': 'Veuillez envoyer votre fichier de logo par courrier électronique à',
      currentPrice: "Prix actuel",
      minQty: "QTÉ minimale d’unités",
      quantity: "Quantité",
      quantityab: "Qté",
      enterQuantity: "Entrez Votre Qté",
      totalQuantity: "Quantité totale",
      minQtyReq: "Quantité minimale de commande groupée",
      totalQty: "Quantité totale actuelle de la commande groupée",
      reserveNow: "Réservez maintenant",
      emailLogo:
        "Veuillez nous transmettre par courriel un fichier contenant votre logo à l’adresse",
      programThankyou:
        "Les produits commandés grâce au programme Réservez constituent une vente finale. La facture sera établie au dernier prix réduit en fonction de la quantité commandée par le groupe. On vous facturera les achats au moment de l’expédition, qui pourrait prendre jusqu’à six mois.",
    },
    customizeIt: {
      chooseLogoPlacement: "CHOISIR L’EMPLACEMENT DU LOGO",
      coloursAvail: "Couleurs disponibles",
      addLogosCost: "Coût des logos supplémentaires",
      costCurrentDesign:
        "Votre prix pour cet article avec votre design personnalisé ",
      placeOrder: "Passer la commande selon la conception souhaitée",
      platformWarning:
        "*La plateforme de conception ne sert que de repère; les épreuves finales seront fournies par TNT pour approbation finale.",
      enterOrderQty: "Entrez la quantité de commande ",
      totalQuantity: "Qté totale",
      totalQuantityab: "Qté",
      changeLogo: "Changer le logo actuel",
      yourLogos: "Vos logos",
      clearLogo: "Supprimer le logo",
      uploadLogo: "Téléverser votre logo",
      emailQuote: "Envoyer un courriel pour obtenir une soumission",
      guideOnly:
        "Ceci est une représentation virtuelle seulement. Nous vous enverrons une épreuve finale par courriel pour approbation avant d’amorcer la production.",
      customLogos: "Logos personnalisés",
      specialInst: "Instructions Spéciales",
      logosLowAs:
        "Notre prix comprend un logo. Les logos supplémentaires sont aussi bas que {logosMinPrice}. Les prix ci-dessous seront mis à jour au fur et à mesure que vous créerez votre design.",
      defaultLogo: "Logo par défaut : GRATUIT",
      customThankyou:
        'Veuillez noter que les commandes Personnalisez-le sont fabriquées sur commande et qu’il faut compter jusqu’à deux ou trois semaines pour la livraison. Un représentant du service à la clientèle Honda communiquera avec vous pour vous soumettre l’épreuve finale avant le lancement de la production. Les commandes "Personnalisez-le" sont faites sur commande et sont une vente finale. Les articles autres que les vêtements peuvent être soumis à un 5% de plus ou de moins et vous serez facturé pour la quantité finale expédiée.',
    },
  },
  client: {
    minQtyReq: "Min. Qty.:",
    navigation: {
      home: "ACCUEIL",
      contactUs: "Contactez Nous",
      login: "S'IDENTIFIER",
      logout: "SE DÉCONNECTER",
      myCart: "MON PANIER",
      noItems: "PAS D'OBJET",
      item: "ARTICLE",
      items: "ARTICLES",
      units: "UNITÉS",
      returns: "Résultats",
      sizeCharts: "Tableaux des tailles",
      userGuide: "Mode d'emploi",
      currency: "Dollar canadien (CAD)",
      policies: "Politique",
      support: "Soutien",
    },
    prodNav: {
      shopMen: "Boutique pour hommes",
      shopWomen: "Boutique pour femmes",
      tShirts: "Tee-shirts",
      polos: "Polos",
      fleece: "Molletons",
      jackets: "Blousons",
      headwear: "Casquettes, etc.",
      classicBRAND: "Honda Classique",
      new: "NOUVEAU",
      pe: "PE",
      marine: "Honda Marine",
      engine: "Honda Moteurs",
      counterShirts: "Chemises de comptoir",
      drinkware: "Drinkware",
      office: "Bureau",
      bags: "Des sacs",
      specials: "Magaziner le solde",
      clearance: "Dégagement",
      goldwing: "Goldwing",
      "show-shirts": "Polo Honda",
      tech: "Technologie",
      gifts: "Cadeaux",
      safety: "Protéger",
    },
    login: {
      title: "Bienvenue à {siteName}.com",
      message: "Veuillez ouvrir une session",
      email: "Courriel",
      username: "Nom d’utilisateur",
      password: "Mot de passe",
      forgotPassword: "Vous avez oublié votre mot de passe?",
      account: "Vous n’avez pas de compte?",
      request: "Ouvrir un compte",
      resetPassword: "Réinitialiser le mot de passe",
      backToLogin: "Retour à l'écran de connexion",
      firstTime:
        "Les nouveaux utilisateurs doivent être approuvés par {brand} avant de recevoir un mot de passe.",
      hoManagement: "H.O. La gestion",
      dealerManagement: "Gestion des concessionnaires",
      hoAssociate: "H.O. Associer",
      dealerStaff: "Personnel du concessionnaire",
      managementMarketingTeam: "Équipe de Gestion du Marketing",
      branchManager: "Directeurs de Succursales",
      executiveAssistant: "Adjoints Exécutifs",
      categoryManager: "Gestionnaires de Categories",
      sales: "Ventes",
      employee: "Employé",
      customer: "Client",
      vendor: "Vendor",
      carrierDealer: "Carrier Dealer",
      bryantDealer: "Bryant Dealer",
      carrierFadDealer: "Carrier FAD Dealer",
      bryantFadDealer: "Bryant FAD Dealer",
      ceManagement: "CE Management",
      ceEmployee: "CE Employee",
      select: "Sélectionner",
      viewOtherLang: "View this page in English",
      submitted: "Votre demande a été soumise.",
      submittedNoApproval: "Opération réussie! Votre compte est configuré",
      notification: "Vous recevrez un avis d’approbation par courriel.",
      notificationNoApproval:
        "Veuillez consulter votre boîte de réception; vous y trouverez un courriel contenant un lien pour terminer la configuration de votre compte.",
      receivePassword:
        "Vous devriez recevoir sous peu par courriel un nouveau mot de passe.",
    },
    activate: {
      setPassword:
        "Veuillez choisir un nouveau mot de passe pour activer votre compte.",
      passwordUpdated:
        "Mot de passe mis à jour avec succès! Chargement de la page de connexion...",
      activateAcc: "Activez votre nouveau compte à la boutique Honda.",
      confirmPassword: "Confirmez le mot de passe",
      wrong: "Quelque-chose s'est mal passé...",
      enterUsername: "Entrez un nom d'utilisateur",
    },
    home: {
      welcome: "Bienvenue",
      doToday: "Que souhaitez-vous faire aujourd’hui?",
      days: "JOURS",
      hours: "HRS",
      mins: "MIN",
      secs: "SECS",
      timeLeft: "Temps restant pour effectuer une réservation",
      nextBooking: "Prochaine réservation à partir du",
      nextBookingSoon: "Arrive Bientôt",
    },
    product: {
      zoom: "Zoom",
      related: "Autres",
      new: "NOUVEAU!",
      clearance: "DÉGAGEMENT!",
    },
    cart: {
      title: "Votre panier",
      cartEmpty: "Votre chariot est vide!",
      willBeCalculated:
        "Les frais de livraison et les taxes seront ajoutés au moment du passage à la caisse.",
    },
    checkout: {
      title: "Passer la commande",
      customerInfo: "Renseignements sur le client",
      shipMethod: "Méthode d'envoi",
      shipInfo: "Informations sur la livraison",
      payMethod: "Méthode de paiement",
      payInfo: "Informations de paiement",
      orderSummary: "Résumé de la commande",
      shipAdd: "Adresse de livraison",
      billAdd: "Adresse de facturation",
      payAdd: "Adresse de facturation",
      shippingOptions: "Options d'expédition",
      upsShipOptions: "Options de livraison UPS",
      canpostShipOptions: "Options de livraison Canada Post",
      shipWeight: "Poids d’expédition",
      creditCard: "Carte de crédit",
      po: "Bon de commande",
      poNo: "Bon de commande",
      poRef: "Bon de commande",
      secureMsg:
        "Toutes les opérations sont sécurisées et chiffrées. Les données des cartes de crédit ne sont jamais conservées.",
      detailMsg:
        "Certains détails de votre commande doivent être fixés avant de pouvoir la caisse.",
      poMsg:
        "Toutes les commandes supérieures à 300 $ seront admissibles aux modalités de paiement « net 30 jours ». Les commandes ne sont pas facturées tant qu’elles n’ont pas été expédiées. Le paiement est exigible dans les 30 jours suivant l’envoi de la facture pour le maintien des modalités.",
      lbs: "lbs totaux",
      authNo: "Numéro d'autorisation",
      editShipAdd: "Modifier l'adresse d'expédition",
      invalidShippingAddress: "Adresse de livraison incomplète ou non valide",
      editBillAdd: "Modifier l'adresse de facturation",
      closeEdit: "Fermer la fenêtre",
      totalCad: "Total (CAD)",
      totalBeforeCredit: "Total avant crédit (CAD)",
      creditApplied: "Crédit appliqué",
      confirmAmountCredit: "Confirm amount from Credit",
      inventoryWarningRow:
        "L’article que vous avez ajouté à votre panier n’est plus offert en quantité suffisante pour passer votre commande.",
      inventoryWarningCart:
        "Un ou plusieurs articles que vous avez ajoutés à votre panier ne sont pas offerts en quantité suffisante pour passer votre commande. Veuillez modifier vos quantités de manière à ce qu’elles ne dépassent pas les stocks disponibles.",
      availableInventory: "Stocks disponibles =",
      shippingTieredOver: "TBC",
      shippingStandardOver: "TBD",
      shipping: {
        weightOver:
          "En raison de la valeur de votre commande, nous communiquerons avec vous plus tard pour vous confirmer le coût d’expédition final. Un tarif fixe de 200 $ sera ajouté à la caisse pour l’instant.",
        valueOver:
          "En raison de la valeur de votre commande, nous communiquerons avec vous plus tard pour vous confirmer le coût d’expédition final.",
      },
      payment: {
        card: "Caractères numériques uniquement, sans traits d'union, espaces ou barres obliques",
        expiry:
          "4 caractères numériques seulement (MMYY), sans traits d'union, espaces ou barres obliques",
        cvd: "3 ou 4 caractères numériques seulement",
      },
      receipt: {
        receipt: "Receipt",
        title: "Facture d'achat",
        receiptTitle: "Confirmation de commande",
        customTitle: "Confirmation de commande personnalisée",
        resTitle: "Votre réservation est confirmée",
        thankYou: "Merci de votre commande!",
        transfer: "Merci!",
        // 'message': 'Vous recevrez un reçu de préautorisation par courriel et le reçu final lorsque votre commande sera expédiée.',
        message:
          "Customize It items are made to order and can take up to 2 – 3 weeks for delivery. You will be emailed a final proof for approval before production. Your Honda CSR rep will provide an estimated delivery date upon proof approval.",
        ecertMessage: "Vous recevrez bientôt un reçu par courriel.",
        issueInvoice: "Nous vous émettons bientôt une facture.",
        emailInvoice:
          "Une facture vous sera envoyée par courrier électronique.",
      },
      discountStr: "NOUVELLE STRUCTURE DE REMISE !",
    },
    myAccount: {
      title: "Mon compte",
      myOrders: "Mes commandes",
      viewOrders: "Afficher et imprimer les factures des commandes passées",
      accountSettings: "Paramètres de mon compte",
      viewInfo: "View and manage your customer information",
      manage: "Visualisez et gérez tous vos eCertificats",
      shoppingResources: "Centres Ressources",
      myCart: "Mon panier",
      viewCart: "Voir panier produits actuels",
      viewCartEmpty: "Vous n'avez aucun article dans votre panier",
      customerInfo: {
        title: "Informations client",
        userInfo: "informations de l'utilisateur",
        manageLocations: "Gérer les emplacements",
        personalLoc: "Sites personnels",
        BRANDLoc: "Sites Honda",
        checkoutDetails: "Commander par défaut",
        defaultShip: "Navire défaut Pour Localisation",
        setDefaultShip: "Définir un défaut Adresse d'expédition",
        defaultBill: "Par défaut Bill Pour Localisation",
        setDefaultBill: "Définir une facturation par défaut Adresse",
        addLocation: "Ajouter un emplacement",
        backOrders: "Retour aux commandes",
        backMyAccount: "Retour à mon compte",
        editAdd: "Modifier l'adresse",
        deleteAdd: "Supprimer l'adresse",
      },
      orderHistory: {
        title: "Historique des commandes",
        orderDetails: "Détails de la commande",
        orderInvoice: "Order Invoice",
        viewInvoice: "View Invoice",
        reservationDetails: "Détails de la réservation",
        orderInfo: "Informations de commande",
        reservationInfo: "Info réservation",
        orderItems: "Dans l'ordre",
        reservationItems: "Articles de réservation",
        getItOrders: "Get It Now Orders",
        bookItReservations: "Book It Reservations",
        standard: "Procurez-vous-le maintenant",
        custom: "Procurez-vous-le maintenant",
        reservation: "Réservez-le",
        credit: "eCertificat",
      },
      ecertificate: {
        title: "eCertificats",
        ecertificate: "eCertificat",
        transferReceipt: "eCertificates Transfer Receipt",
        myEcertificates: "Mes eCertificats",
        currentBalance: "Solde actuel",
        history: "Histoire",
        purchase: "Achat",
        label: "Titre",
        optional: "Optionnel",
        amount: "Montant",
        expire:
          "Avis d’expiration : les certificats électroniques achetés aujourd’hui expireront le",
        recipient: "Bénéficiaire",
        recipientEmail: "Courriel du destinataire",
        recipientMessage:
          "Le destinataire doit être un utilisateur enregistré.",
        yourMessage: "Votre message",
        enterYourMessage: "Entrez votre message",
        anotherUser: "Un autre utilisateur",
        myself: "Moi même",
        buy: "Acheter",
      },
      changePassword: {
        title: "Changer le mot de passe",
        currentPass: "Mot de passe actuel",
        newPass: "Nouveau mot de passe",
        confirmPass: "Confirmer le nouveau mot de passe",
      },
      vault: {
        managePayMeth: "Gérer les méthodes de paiement",
        addCard: "Ajouter une carte",
        submitCard: "Soumettre la carte",
        vaultNoCard:
          "There is no credit card attached to your account. Please add a card.",
      },
    },
  },
};

export default fr;
